import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import Loader from './components/common/Loader/Loader';
import LoginError from './pages/errors/LoginError';
import PageNotFound from './pages/errors/PageNotFound';
import Home from './pages/Home';
import TechRadar from './pages/TechRadar';
import TechRadarWidget from './pages/TechRadarWidget';
import keycloak, { getPrincipal } from './security/keycloak';
import { radarErrorSelector } from './store/selectors/radarsSelectors';
import './assets/scss/main.scss';

const App = () => {
  const [doesUserHaveRole, setDoesUserHaveRole] = useState(false);
  const [loading, setLoading] = useState(true);

  const { error } = useSelector(radarErrorSelector);

  const checkRoles = () => {
    getPrincipal()
      .then(() => {
        setDoesUserHaveRole(keycloak.principal.role !== undefined);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const keycloakEventHandler = (event) => {
    if (event === 'onAuthSuccess') {
      checkRoles();
    }
    if (event === 'onAuthRefreshSuccess') {
      checkRoles();
    }
    if (event === 'onAuthError' || event === 'onAuthRefreshError') {
      keycloak.clearToken();
      keycloak.logout();
    }
  };

  if (process.env.REACT_APP_WIDGET === 'true') {
    return (
      <Routes>
        {!error ? (
          <>
            <Route path="/:radarName" element={<TechRadarWidget />} />
            <Route path="*" element={<PageNotFound isWidget={true} />} />
          </>
        ) : (
          <Route path="/*" element={<PageNotFound isWidget={true} />} />
        )}
      </Routes>
    );
  } else {
    return (
      <Routes>
        {
          <>
            <Route path="/techradar-widget/*" element={<PageNotFound />} />
            <Route
              path="/*"
              element={
                <ReactKeycloakProvider
                  authClient={keycloak}
                  initOptions={{
                    checkLoginIframe: false,
                    onLoad: 'login-required'
                  }}
                  onEvent={keycloakEventHandler}>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Routes>
                      {error ? (
                        <Route path="*" element={<PageNotFound />} />
                      ) : doesUserHaveRole ? (
                        <>
                          <Route path="/" element={<Home />} />
                          <Route path="/:radarName" element={<TechRadar />} />
                        </>
                      ) : (
                        <Route path="/" element={<LoginError />} />
                      )}
                    </Routes>
                  )}
                </ReactKeycloakProvider>
              }
            />
          </>
        }
      </Routes>
    );
  }
};

export default App;
