import * as d3 from 'd3';

const HandleItemOpacityChange = (isTechnologyHovered, isTechnologySelected) => {
  const activeNode = d3.select("[data-item='item-active']").node();

  if (isTechnologyHovered || isTechnologySelected || activeNode) {
    d3.selectAll("[data-item='item']").attr('opacity', 0.6);
    d3.selectAll("[data-item='item-active']").attr('opacity', 1);
  } else {
    d3.selectAll("[data-item='item']").attr('opacity', 1);
  }
};

export default HandleItemOpacityChange;
