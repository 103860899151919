import {
  GET_ALL_RADARS,
  SET_CURRENT_RADAR,
  SET_FILTERED_RADAR,
  SET_RADAR_ERROR,
  SET_RADARS
} from '../actions/radarsActions';

const defaultState = {
  radars: [],
  currentRadar: {},
  filteredRadar: {},
  error: {}
};

const radarsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_RADARS:
      return { ...state, radars: action.payload };
    case SET_RADARS:
      return {
        ...state,
        radars: action.payload
      };
    case SET_CURRENT_RADAR:
      return {
        ...state,
        currentRadar: action.payload
      };
    case SET_FILTERED_RADAR:
      return {
        ...state,
        filteredRadar: action.payload
      };
    case SET_RADAR_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default radarsReducer;
