import sendAnalytic from '../../api/sendAnalytic';

export const SET_ACTIVE_QUADRANT = 'SET_ACTIVE_QUADRANT';
export const SET_HOVERED_TECHNOLOGY = 'SET_HOVERED_TECHNOLOGY';
export const SET_SELECTED_TECHNOLOGY = 'SET_SELECTED_TECHNOLOGY';

export const setActiveQuadrantAction = (payload) => ({
  type: SET_ACTIVE_QUADRANT,
  payload
});
export const setHoveredTechnologyAction = (payload) => ({
  type: SET_HOVERED_TECHNOLOGY,
  payload
});
export const setSelectedTechnologyAction = (payload) => ({
  type: SET_SELECTED_TECHNOLOGY,
  payload
});
export const setAnalyticSelectedTechnologyAction = (payload) => {
  return (dispatch) => {
    sendAnalytic(payload);
    dispatch(setSelectedTechnologyAction(payload));
  };
};
