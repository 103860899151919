import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

const ContactButton = ({ email, technologyName, fontSize }) => {
  const { radarName } = useParams();

  return (
    <Link
      href={`mailto:${email}?subject=Techradar:${radarName}:${technologyName}`}
      data-testid="contact-button">
      <MailOutlineIcon sx={{ color: '#ee0b77' }} fontSize={fontSize} />
    </Link>
  );
};

ContactButton.propTypes = {
  fontSize: PropTypes.string.isRequired,
  email: PropTypes.string,
  technologyName: PropTypes.string
};

export default ContactButton;
