import PropTypes from 'prop-types';
import React from 'react';

const Triangle = ({ width, height, fill, numberOfUsers, fontSize }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 503.754 503.754"
    width={width}
    height={height}
    data-testid="triangle">
    <path
      fill={fill}
      d="M492.654,382.025l-187.52-325.12c-5.533-9.957-13.742-18.167-23.699-23.699c-29.455-16.366-66.601-5.756-82.967,23.699
        L8.174,386.719C2.808,396.014-0.012,406.56,0,417.293c0.037,33.697,27.384,60.983,61.081,60.946h380.587l1.707-0.213
        c33.364-0.35,60.259-27.435,60.373-60.8C503.933,404.604,500.043,392.26,492.654,382.025z"
    />
    <text className="number-of-users" x="50%" y="50%" style={{ fontSize: `${fontSize}em` }}>
      {!numberOfUsers ? '' : numberOfUsers}
    </text>
  </svg>
);

Triangle.propTypes = {
  fill: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string
};

export default Triangle;
