import { SET_CONTACT_DIALOG_DATA, SET_CONTACT_DIALOG_OPEN } from '../actions/contactDialogActions';

const defaultState = {
  open: false,
  dialogData: {}
};

const contactDialogReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CONTACT_DIALOG_OPEN:
      return { ...state, open: action.payload };
    case SET_CONTACT_DIALOG_DATA:
      return { ...state, dialogData: action.payload };
    default:
      return state;
  }
};

export default contactDialogReducer;
