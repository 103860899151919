import { Clear, SearchSharp } from '@mui/icons-material';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import sendAnalytic from '../../../api/sendAnalytic';
import './search.scss';

const Search = ({ value, setValue, hasSearchAnalytic, setHasSearchAnalytic, timerRef }) => {
  const handleClear = () => {
    setValue('');
    if (timerRef) {
      clearTimeout(timerRef.current);
      setHasSearchAnalytic(true);
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
    if (!!e.target.value === false && timerRef) {
      setHasSearchAnalytic(true);
    }
    if (timerRef && !!e.target.value && hasSearchAnalytic) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        if (e.target.value && process.env.REACT_APP_WIDGET !== 'true') {
          sendAnalytic(e.target.value);
        }
      }, 3000);
    }
  };

  return (
    <FormControl className="search">
      <TextField
        value={value}
        size="small"
        label="Search"
        variant="outlined"
        className="search-field"
        onChange={(e) => onChange(e)}
        data-testid="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchSharp />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={handleClear}>
              <Clear className="search-icon" />
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

Search.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  data: PropTypes.shape({
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
        projectName: PropTypes.string.isRequired,
        technologyRing: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired
      })
    ).isRequired,
    index: PropTypes.number.isRequired,
    isNew: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    quadrant: PropTypes.string.isRequired,
    ring: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    technologyDescription: PropTypes.string.isRequired
  }),
  setHasSearchAnalytic: PropTypes.func,
  timerRef: PropTypes.object
};

export default Search;
