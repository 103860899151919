import axios from 'axios';

import createRadarSetup from '../../helpers/createRadarSetup';
import { getToken } from '../../security/keycloak';
import { setCurrentRadarAction, setRadarError } from '../actions/radarsActions';

const widgetUrl = process.env.REACT_APP_BUCKET_URL;

export const fetchCurrentRadarData = (radarName) => {
  return async (dispatch, getState) => {
    const { radarsReducer } = getState();

    const radar = radarsReducer.radars.find((radar) => radar.radarName === radarName);
    if (!radar) {
      dispatch(
        setRadarError({
          error: { error: true, message: 'The requested radar is not found' }
        })
      );
      return;
    }
    const { data } = await axios(radar.radarUrl, {
      headers: {
        Authorization: getToken()
      }
    });
    const setup = createRadarSetup(data);
    dispatch(setCurrentRadarAction(setup));
  };
};

export const fetchWidgetCurrentRadarData = (radarName) => {
  return async function (dispatch) {
    await axios(`${widgetUrl}${radarName}.json`, {})
      .then((request) => {
        const setup = createRadarSetup(request.data);
        dispatch(setCurrentRadarAction(setup));
      })
      .catch(() => {
        dispatch(
          setRadarError({
            error: { error: true, message: 'The requested widget is not found' }
          })
        );
      });
  };
};
