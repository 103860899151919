import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveQuadrant } from '../../../store/selectors/quadrantSelectors';
import Circle from '../../Svg/Circle';
import Triangle from '../../Svg/Triangle';
import './legend.scss';

const Legend = () => {
  const activeQuadrant = useSelector(getActiveQuadrant);
  const LEGEND_ITEM_COLOR = '#999999';

  return (
    <>
      {activeQuadrant !== null && (
        <div className="legend">
          <div className="legend-item">
            <Triangle width="20" height="20" fill={LEGEND_ITEM_COLOR} />
            New or moved
          </div>
          <div className="legend-item">
            <Circle width="20" height="20" fill={LEGEND_ITEM_COLOR} />
            No change
          </div>
        </div>
      )}
    </>
  );
};

export default Legend;
