import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loader = () => {
  return (
    <div className="section-loading">
      <Box sx={{ display: 'flex' }}>
        <CircularProgress style={{ color: '#8B94B5' }} thickness={2.5} size={70} />
      </Box>
    </div>
  );
};

export default Loader;
