import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getActiveQuadrant } from '../store/selectors/quadrantSelectors';

const getTranslateForInitialScale = (index) => {
  switch (index) {
    case 0:
      return 'translate(5px, 5px)';
    case 1:
      return 'translate(-5px, 5px)';
    case 2:
      return 'translate(-5px, -5px)';
    case 3:
      return 'translate(5px, -5px)';
    default:
      break;
  }
};

export function useScaleQuadrant(props) {
  const activeQuadrant = useSelector(getActiveQuadrant);

  const ref = useRef(null);

  useEffect(() => {
    const svg = ref.current && d3.select(ref.current);
    if (!svg) {
      return;
    }

    const initialScaleTransform = `${getTranslateForInitialScale(props.index)} rotate(${props.rotateDegrees}deg) scale(1)`;
    const enlargedTransform = `rotate(${props.rotateDegrees}deg) translate(-320px, -320px) scale(1.5)`;

    const applyTransform = (transform) =>
      svg.transition().duration(0).style('transform', transform);

    applyTransform(initialScaleTransform);

    if (activeQuadrant === null || activeQuadrant === props.index) {
      ref.current.style.opacity = '1.0';

      if (activeQuadrant !== null) {
        applyTransform(enlargedTransform);
      }
    }
  }, [activeQuadrant, props.index, props.rotateDegrees, ref]);

  return { ref };
}
