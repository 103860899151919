import * as d3 from 'd3';
import { createContext } from 'react';

const DEFAULT_FONT_SIZE = 12;
const DEFAULT_COLOR_SCALE = d3.scaleOrdinal(['#999999']);
const DEFAULT_FONT_FAMILY = 'Arial, Helvetica, sans-serif';

export const ThemeContext = createContext({
  colorScale: DEFAULT_COLOR_SCALE,
  fontFamily: DEFAULT_FONT_FAMILY,
  fontSize: DEFAULT_FONT_SIZE,
  itemFontSize: DEFAULT_FONT_SIZE,
  quadrantsConfig: {}
});
