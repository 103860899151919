import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ItemWrapper } from './Item.style';
import { itemColors } from './itemColors';
import HandleItemOpacityChange from '../../../helpers/handleItemOpacityChange';
import { technologyCategory } from '../../../models/technologyCategory';
import {
  getIsHoveredTechnology,
  getIsTechnologySelected
} from '../../../store/selectors/quadrantSelectors';
import TooltipButton from '../TooltipButton/TooltipButton';

const Item = ({ rotateDegrees, numberOfUsers, name, isNew, quadrant, x, y, size, fontSize }) => {
  let ref = useRef(null);

  const isTechnologyHovered = useSelector((state) => getIsHoveredTechnology(state, name));
  const isTechnologySelected = useSelector((state) => getIsTechnologySelected(state, name));

  const getColor = (quadrant) => {
    switch (quadrant) {
      case technologyCategory.TOOLS:
        return itemColors.TOOLS;
      case technologyCategory.TECHNIQUES:
        return itemColors.TECHNIQUES;
      case technologyCategory.PLATFORMS:
        return itemColors.PLATFORMS;
      case technologyCategory.LANGUAGES_FRAMEWORKS:
        return itemColors.LANGUAGES_FRAMEWORKS;
      default:
        break;
    }
  };

  useEffect(() => {
    HandleItemOpacityChange(isTechnologyHovered, isTechnologySelected);
  }, [isTechnologyHovered, isTechnologySelected]);

  return (
    <ItemWrapper
      transform={`rotate(${rotateDegrees}) translate(${x}, ${y})`}
      ref={ref}
      data-item={`${isTechnologyHovered || isTechnologySelected ? 'item-active' : 'item'}`}>
      <TooltipButton
        numberOfUsers={numberOfUsers}
        title={name}
        category={getColor(quadrant)}
        isNew={isNew}
        quadrant={quadrant}
        size={size}
        fontSize={fontSize}
        open={isTechnologyHovered || isTechnologySelected}
        itemRef={ref}
      />
    </ItemWrapper>
  );
};

Item.propTypes = {
  fontSize: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  quadrant: PropTypes.string.isRequired,
  rotateDegrees: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  numberOfUsers: PropTypes.number
};

export default Item;
