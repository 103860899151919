import {
  SET_ACTIVE_QUADRANT,
  SET_HOVERED_TECHNOLOGY,
  SET_SELECTED_TECHNOLOGY
} from '../actions/quadrantActions';

const defaultState = {
  activeQuadrant: null,
  hoveredTechnology: '',
  selectedTechnology: ''
};

const quadrantReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_QUADRANT:
      return {
        ...state,
        activeQuadrant: action.payload
      };
    case SET_HOVERED_TECHNOLOGY:
      return {
        ...state,
        hoveredTechnology: action.payload
      };
    case SET_SELECTED_TECHNOLOGY:
      return {
        ...state,
        selectedTechnology: state.selectedTechnology !== action.payload ? action.payload : ''
      };
    default:
      return state;
  }
};

export default quadrantReducer;
