import { createSelector } from 'reselect';

const allRadarsSelector = (state) => {
  return state.radarsReducer.radars;
};

const currentRadarSelector = (state) => state.radarsReducer.currentRadar;
const filteredRadarSelector = (state) => state.radarsReducer.filteredRadar;

export const getAllRadars = createSelector(allRadarsSelector, (allRadars) => allRadars);
export const getCurrentRadar = createSelector(currentRadarSelector, (currentRadar) => currentRadar);
export const getFilteredRadar = createSelector(
  filteredRadarSelector,
  (filteredRadar) => filteredRadar
);
export const radarErrorSelector = (state) => state.radarsReducer.error;
