import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import createContactDialogData from '../../../helpers/createContactDialogData';
import {
  setContactDialogData,
  setContactDialogOpen
} from '../../../store/actions/contactDialogActions';
import './technologyItemInfo.scss';

const TechnologyItemInfo = ({ technologyDescription, contacts, tags, name }) => {
  const dispatch = useDispatch();

  const onMouseClick = (contact) => {
    dispatch(setContactDialogOpen(true));
    const dialogData = createContactDialogData(contact, name, technologyDescription);
    dispatch(setContactDialogData(dialogData));
  };

  return (
    <>
      <div className="description">
        <p>{technologyDescription}</p>
      </div>
      <div className="tags">
        {tags.map(({ name }) => (
          <p key={name}>#{name}</p>
        ))}
      </div>
      <div className="contacts">
        {contacts?.map((contact) => (
          <p className="contact-name" onClick={() => onMouseClick(contact)} key={contact.uuid}>
            {`${contact.fullName} (${contact.projectName})`}
          </p>
        ))}
      </div>
    </>
  );
};

TechnologyItemInfo.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  technologyDescription: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      projectName: PropTypes.string.isRequired,
      technologyRing: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  )
};

export default TechnologyItemInfo;
