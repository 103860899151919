import { FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

import SwitchButton from './SwitchButton/SwitchButton';
import sendTag from '../../api/sendTag';

const TagsFilter = ({
  options,
  setSelectedTags,
  activeToggle,
  setActiveToggle,
  hasSearchAnalytic,
  setHasSearchAnalytic,
  timerRef
}) => {
  const onInput = (e) => {
    if (!!e.target.value === false && timerRef) {
      setHasSearchAnalytic(true);
    }
    if (timerRef && !!e.target.value && hasSearchAnalytic) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        e.target.value && sendTag(e.target.value);
      }, 3000);
    }
  };
  const onChange = (option) => {
    const transformTags = option.map(({ label }) => label);
    setSelectedTags(transformTags);
  };

  const pushTag = (tagName) => {
    if (tagName) {
      sendTag(tagName);
    }
  };

  return (
    <FormControl className="search">
      <Autocomplete
        multiple
        id="combo-box-demo"
        size="small"
        data-testid="autocomplete"
        className="search-dropdown"
        options={options}
        filterSelectedOptions
        onInput={(e) => {
          onInput(e);
        }}
        onChange={(event, option) => {
          pushTag(event.target.innerText);
          onChange(option);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select tags" className="search-field" />
        )}
      />
      <SwitchButton activeToggle={activeToggle} setActiveToggle={setActiveToggle} />
    </FormControl>
  );
};

TagsFilter.propTypes = {
  options: PropTypes.array.isRequired,
  setSelectedTags: PropTypes.func
};

export default TagsFilter;
