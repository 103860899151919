import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setActiveQuadrantAction,
  setAnalyticSelectedTechnologyAction,
  setHoveredTechnologyAction
} from '../../store/actions/quadrantActions';
import { getIsTechnologySelected } from '../../store/selectors/quadrantSelectors';
import './searchList.scss';

const SearchList = ({ data, setValue, value, setHasSearchAnalytic, timerRef }) => {
  const dispatch = useDispatch();
  const [tech, setTech] = useState({});

  const isTechnologySelected = useSelector((state) => getIsTechnologySelected(state));

  useEffect(() => {
    const quadrants = document.querySelectorAll('.quadrant-wrapper');
    quadrants.forEach(({ id }, index) => {
      if (id === tech.quadrant) {
        dispatch(setAnalyticSelectedTechnologyAction(value));
        dispatch(setActiveQuadrantAction(index));
      }
    });
  }, [tech]);

  useEffect(() => {
    if (!isTechnologySelected) {
      setTech({});
    }
  }, [isTechnologySelected]);

  const setTechnology = (technology) => {
    setValue(technology.name);
    setTech(technology);
    setHasSearchAnalytic(false);
    clearTimeout(timerRef.current);
  };

  const onMouseOver = (technology) => {
    dispatch(setHoveredTechnologyAction(technology.name));
  };

  const onMouseOut = () => {
    dispatch(setHoveredTechnologyAction(''));
  };
  const dis = useRef('visible');
  return (
    <div
      className="searchList"
      style={{ visibility: dis.current === 'visible' ? 'visible' : 'hidden' }}>
      {data?.length !== 0 ? (
        data.map((technology, i) => {
          return (
            <ListItem key={i} disablePadding>
              <ListItemButton
                onClick={() => {
                  setTechnology(technology);
                  dis.current = 'hidden';
                }}
                onMouseOver={() => onMouseOver(technology)}
                onMouseOut={onMouseOut}>
                <ListItemText id={i} primary={technology.name} />
              </ListItemButton>
            </ListItem>
          );
        })
      ) : (
        <div className="searchList_notFound">Technologies Not Found</div>
      )}
    </div>
  );
};

export default SearchList;
