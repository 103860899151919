import createRadarSetup from '../../helpers/createRadarSetup';

export const GET_ALL_RADARS = 'GET_ALL_RADARS';
export const SET_CURRENT_RADAR = 'SET_CURRENT_RADAR';
export const SET_FILTERED_RADAR = 'SET_FILTERED_RADAR';
export const SET_RADAR_ERROR = 'SET_RADAR_ERROR';
export const SET_RADARS = 'SET_RADARS';

export const getAllRadarsAction = (payload) => ({
  type: GET_ALL_RADARS,
  payload
});

export const setAllRadarsAction = (payload) => ({
  type: SET_RADARS,
  payload
});

export const setCurrentRadarAction = (payload) => ({
  type: SET_CURRENT_RADAR,
  payload
});
export const setFilteredRadarAction = (payload) => ({
  type: SET_FILTERED_RADAR,
  payload
});

export const setFilteredRadar = (filteredRadar) => {
  return (dispatch) => {
    const setup = createRadarSetup(filteredRadar);
    dispatch(setFilteredRadarAction(setup));
  };
};

export const setRadarError = (payload) => ({ type: SET_RADAR_ERROR, payload });
