import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import GoBackButton from '../../components/common/GoBackButton/GoBackButton';
import { radarErrorSelector } from '../../store/selectors/radarsSelectors';

const PageNotFound = ({ isWidget }) => {
  const navigate = useNavigate();

  const { error } = useSelector(radarErrorSelector);

  console.log(error);
  const onMouseClick = () => {
    navigate('/');
    navigate(0);
  };

  return (
    <main className="page-not-found">
      <p className="status">404</p>
      <p className="message">
        {error && error.error === true
          ? error.message
          : 'The requested ' + (isWidget ? 'widget page' : 'page') + ' could not be found.'}{' '}
      </p>
      {!isWidget && <GoBackButton onClick={onMouseClick} />}
    </main>
  );
};

export default PageNotFound;
