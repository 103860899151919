//radius of rings diminish as they move away from the center
const RADIUS_DIMINISH_CONSTANT = 1.5;
const calculateRadiusDiminish = (nrOfRings) => {
  let max = 1;

  //create the array. each value represents
  //the share of total radius among rings.
  let arr = [1];
  for (let i = 1; i < nrOfRings; i++) {
    max = max * RADIUS_DIMINISH_CONSTANT;
    arr.push(max);
  }

  //calculate total shares of radius
  const sum = arr.reduce((a, b) => a + b);
  arr = arr.map((a) => a / sum);

  //now, each member of the array represent
  //the starting position of ring in the
  //circle
  arr.reverse();
  for (let i = 1; i < nrOfRings; i++) {
    arr[i] = arr[i - 1] + arr[i];
  }

  //add 0 for the center of the circle
  arr.push(0);

  //sort the array so that 0 is at the start
  arr.sort();

  return arr;
};

export default calculateRadiusDiminish;
