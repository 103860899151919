import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common,
    fontSize: theme.typography.pxToRem(15)
  }
}));
