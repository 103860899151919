import axios from 'axios';

import { getToken } from '../security/keycloak';

const sendAnalytic = (value) => {
  axios.post(process.env.REACT_APP_API_URL + 'analytic/desiredTag/add', value, {
    headers: {
      'Content-Type': 'text/plain',
      Authorization: getToken()
    }
  });
};

export default sendAnalytic;
