import { combineReducers } from 'redux';

import contactDialogReducer from './contactDialogReducer';
import quadrantReducer from './quadrantReducer';
import radarsReducer from './radarsReducer';

export const rootReducer = combineReducers({
  radarsReducer,
  quadrantReducer,
  contactDialogReducer
});
