import { createSelector } from 'reselect';

const isHoveredTechnologySelector = (state, name) => {
  return state.quadrantReducer.hoveredTechnology === name;
};
const isTechnologySelectedSelector = (state, name) => {
  return state.quadrantReducer.selectedTechnology === name;
};
const activeQuadrantSelector = (state) => state.quadrantReducer.activeQuadrant;

export const getIsHoveredTechnology = createSelector(
  isHoveredTechnologySelector,
  (isHovered) => isHovered
);
export const getIsTechnologySelected = createSelector(
  isTechnologySelectedSelector,
  (isSelected) => isSelected
);
export const getActiveQuadrant = createSelector(
  activeQuadrantSelector,
  (activeQuadrant) => activeQuadrant
);
