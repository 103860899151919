import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setContactDialogOpen } from '../../store/actions/contactDialogActions';
import {
  contactDialogDataSelector,
  isContactDialogOpenSelector
} from '../../store/selectors/contactDialogSelectors';
import ContactButton from '../common/ContactButton/ContactButton';
import './contactDialog.scss';

const ContactDialog = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isContactDialogOpenSelector);
  const dialogData = useSelector(contactDialogDataSelector);

  const { fullName, projectName, description, technologyRing, email, technologyName } = dialogData;

  if (!dialogData) {
    return;
  }

  const handleClose = () => {
    dispatch(setContactDialogOpen(false));
  };

  return (
    <Modal open={isOpen}>
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-header-title">
            <Typography variant="h3" className="name">
              {fullName}
            </Typography>
            <ContactButton email={email} technologyName={technologyName} fontSize="large" />
          </div>
          <IconButton
            onClick={handleClose}
            className="btn-close"
            size="large"
            data-testid="close-button">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <div className="modal-body">
          <Typography sx={{ mt: 2 }}>
            <b>Source:</b> {projectName}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {technologyName} {description && `– ${description}`}
          </Typography>
          <Typography sx={{ mt: 2 }}>Level – {technologyRing}</Typography>
          <Typography sx={{ mt: 2 }}></Typography>
        </div>
      </div>
    </Modal>
  );
};

export default ContactDialog;
