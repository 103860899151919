import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  setActiveQuadrantAction,
  setAnalyticSelectedTechnologyAction,
  setHoveredTechnologyAction
} from '../../../store/actions/quadrantActions';
import Circle from '../../Svg/Circle';
import Triangle from '../../Svg/Triangle';
import './tooltip.scss';

const TooltipButton = ({
  numberOfUsers,
  title,
  isNew,
  category,
  quadrant,
  size,
  fontSize,
  open
}) => {
  const dispatch = useDispatch();

  const [hasSizeChanged, setHasSizeChanged] = useState(false);

  useEffect(() => {
    setHasSizeChanged(true);
  }, [size]);

  useEffect(() => {
    if (hasSizeChanged) {
      setHasSizeChanged(false);
    }
  }, [hasSizeChanged]);

  const onMouseOver = (e) => {
    if (e.target.classList.contains('MuiTooltip-tooltipPlacementBottom')) {
      dispatch(setHoveredTechnologyAction(''));
    } else {
      dispatch(setHoveredTechnologyAction(title));
    }
  };

  const onMouseOut = () => {
    dispatch(setHoveredTechnologyAction(''));
  };

  const onMouseClick = () => {
    const quadrants = document.querySelectorAll('.quadrant-wrapper');

    quadrants.forEach(({ id }, index) => {
      if (id === quadrant) {
        dispatch(setActiveQuadrantAction(index));
        dispatch(setAnalyticSelectedTechnologyAction(title));
      }
    });
  };

  return (
    <>
      {!hasSizeChanged ? (
        <foreignObject
          x="0"
          y="0"
          width={size}
          height={size}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onMouseClick}
          data-testid={`tooltip-${title}`}>
          <Tooltip title={title} open={open}>
            <div className="tooltip">
              {isNew ? (
                <Triangle fill={category} numberOfUsers={numberOfUsers} fontSize={fontSize} />
              ) : (
                <Circle fill={category} numberOfUsers={numberOfUsers} fontSize={fontSize} />
              )}
            </div>
          </Tooltip>
        </foreignObject>
      ) : null}
    </>
  );
};

TooltipButton.propTypes = {
  category: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  quadrant: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  numberOfUsers: PropTypes.number
};

export default TooltipButton;
