import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import RingSection from './RingSection/RingSection';
import './technologiesList.scss';

const TechnologiesList = ({ currentRadar: currentRadar, filteredRadar: filteredRadar }) => {
  const listContainerRef = useRef(null);

  const getData = () => {
    if (Object.keys(filteredRadar).length !== 0) {
      return filteredRadar;
    }
    if (Object.keys(currentRadar).length !== 0) {
      return currentRadar;
    }
  };

  const isRadarDataExists = Object.keys(getData()).length !== 0;

  return (
    <aside className="tech-list" ref={listContainerRef}>
      {isRadarDataExists &&
        getData().rings.map((currentRing) => (
          <RingSection
            key={currentRing}
            currentRing={currentRing}
            radarData={getData()}
            listContainerRef={listContainerRef}
          />
        ))}
    </aside>
  );
};

const radarDataType = PropTypes.shape({
  data: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      quadrant: PropTypes.string.isRequired,
      ring: PropTypes.string.isRequired
    })
  ),
  quadrants: PropTypes.arrayOf(PropTypes.string.isRequired),
  rings: PropTypes.arrayOf(PropTypes.string.isRequired)
}).isRequired;

TechnologiesList.propTypes = {
  currentRadar: radarDataType,
  filteredRadar: radarDataType
};

export default TechnologiesList;
