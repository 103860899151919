import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CategoryButtons from '../components/CategoryButtons/CategoryButtons';
import Loader from '../components/common/Loader/Loader';
import Search from '../components/common/Search/Search';
import ContactDialog from '../components/ContactDialog/ContactDialog';
import Radar from '../components/Radar/Radar';
import SearchList from '../components/SearchList/SearchList';
import TagsFilter from '../components/TagsFilter/TagsFilter';
import TechnologiesList from '../components/TechnologiesList/TechnologiesList';
import createTagsOptions from '../helpers/createTagsOptions';
import filterRadarDataBySearchAndTags from '../helpers/filterRadarDataBySearchAndTags';
import EmptyPage from '../pages/errors/EmptyPage';
import { setActiveQuadrantAction } from '../store/actions/quadrantActions';
import {
  setAllRadarsAction,
  setCurrentRadarAction,
  setFilteredRadar,
  setFilteredRadarAction
} from '../store/actions/radarsActions';
import { fetchWidgetCurrentRadarData } from '../store/asyncActions/currentRadarData';
import { getActiveQuadrant } from '../store/selectors/quadrantSelectors';
import { getCurrentRadar, getFilteredRadar } from '../store/selectors/radarsSelectors';

const TechRadarWidget = () => {
  const { radarName } = useParams();
  const [tagsOptions, setTagsOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeToggle, setActiveToggle] = useState('and');
  const [searchQuery, setSearchQuery] = useState('');
  const [hasSearchAnalytic, setHasSearchAnalytic] = useState(true);
  const dispatch = useDispatch();
  const currentRadar = useSelector(getCurrentRadar);
  const filteredRadar = useSelector(getFilteredRadar);
  const activeQuadrant = useSelector(getActiveQuadrant);
  const timerRef = useRef(null);
  const hasNoRadarData = Object.keys(currentRadar).length === 0 || currentRadar?.data?.length === 0;

  useEffect(() => {
    dispatch(fetchWidgetCurrentRadarData(radarName));
    return () => {
      dispatch(setAllRadarsAction([]));
      dispatch(setCurrentRadarAction({}));
      dispatch(setFilteredRadarAction({}));
      dispatch(setActiveQuadrantAction(null));
    };
  }, []);

  useEffect(() => {
    setTagsOptions(createTagsOptions(currentRadar.data));
  }, [currentRadar]);

  useMemo(() => {
    if (Object.keys(currentRadar).length !== 0) {
      const filteredData = filterRadarDataBySearchAndTags(
        currentRadar.data,
        selectedTags,
        activeToggle,
        searchQuery
      );
      setTagsOptions(createTagsOptions(filteredData));
      dispatch(setFilteredRadar(filteredData));
    }
  }, [selectedTags, activeToggle, searchQuery]);

  return (
    <>
      <main className="main">
        <section className="section">
          {!currentRadar.data ? (
            <Loader />
          ) : hasNoRadarData ? (
            <EmptyPage isWidget={true} />
          ) : (
            <>
              <div className="section-list">
                <CategoryButtons />
                <div className="section-item">
                  <TagsFilter
                    options={tagsOptions}
                    activeToggle={activeToggle}
                    setSelectedTags={setSelectedTags}
                    setActiveToggle={setActiveToggle}
                    hasSearchAnalytic={hasSearchAnalytic}
                    setHasSearchAnalytic={setHasSearchAnalytic}
                    timerRef={timerRef}
                  />
                  <Search
                    value={searchQuery}
                    setValue={setSearchQuery}
                    hasSearchAnalytic={hasSearchAnalytic}
                    setHasSearchAnalytic={setHasSearchAnalytic}
                    timerRef={timerRef}
                  />
                  {searchQuery && filteredRadar.data && (
                    <SearchList
                      data={filteredRadar.data}
                      setValue={setSearchQuery}
                      value={searchQuery}
                      setHasSearchAnalytic={setHasSearchAnalytic}
                      timerRef={timerRef}
                    />
                  )}
                </div>
              </div>
              <div className="main">
                <Radar {...currentRadar} filteredRadar={filteredRadar} />
                {activeQuadrant !== null && (
                  <TechnologiesList currentRadar={currentRadar} filteredRadar={filteredRadar} />
                )}
              </div>
            </>
          )}
        </section>
        <ContactDialog />
      </main>
    </>
  );
};

export default TechRadarWidget;
