const filterRadarDataBySearchAndTags = (data, selectedTags, activeToggle, searchQuery) => {
  const filteredTechnologiesByTags = filterByTags(data, selectedTags, activeToggle);
  return filterBySearchQuery(filteredTechnologiesByTags, searchQuery);
};

const filterByTags = (data, selectedTags, activeToggle) => {
  if (selectedTags?.length !== 0) {
    if (activeToggle === 'or') {
      return data?.filter(({ tags }) => {
        const intersectionArr = tags.filter(({ name }) => selectedTags.includes(name));
        return intersectionArr.length > 0;
      });
    }
    return data.filter(({ tags }) => {
      const tagNames = tags.map((tag) => tag.name);
      return selectedTags.every((tag) => tagNames.includes(tag));
    });
  }
  return data;
};

const filterBySearchQuery = (data, searchQuery) => {
  if (searchQuery) {
    return data.filter((item) => {
      return item.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }
  return data;
};

export default filterRadarDataBySearchAndTags;
