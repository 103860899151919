import { technologyCategory } from '../models/technologyCategory';

const createRadarSetup = (data) => {
  const rings = [...new Set(data.map((technology) => technology.ring))];
  const sortOrder = [
    technologyCategory.LANGUAGES_FRAMEWORKS,
    technologyCategory.TECHNIQUES,
    technologyCategory.PLATFORMS,
    technologyCategory.TOOLS
  ];

  const sortData = (a, b) => {
    const quadrantA = a.quadrant;
    const quadrantB = b.quadrant;
    const indexA = sortOrder.indexOf(quadrantA);
    const indexB = sortOrder.indexOf(quadrantB);

    return indexA - indexB;
  };

  const sortedData = data.sort(sortData);
  const newData = sortedData.map((data, i) => {
    return {
      ...data,
      index: +i + 1
    };
  });

  return {
    rings,
    data: newData,
    quadrants: [
      technologyCategory.TOOLS,
      technologyCategory.PLATFORMS,
      technologyCategory.TECHNIQUES,
      technologyCategory.LANGUAGES_FRAMEWORKS
    ]
  };
};

export default createRadarSetup;
