import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QuadrantWrapper } from './Quadrant.style';
import calculateRadiusDiminish from '../../../helpers/calculateRadiusDiminish';
import { useScaleQuadrant } from '../../../hooks/useScaleQuadrant';
import { useThemeContext } from '../../../hooks/useThemeContext';
import { setActiveQuadrantAction } from '../../../store/actions/quadrantActions';
import { getActiveQuadrant } from '../../../store/selectors/quadrantSelectors';
import Item from '../Item/Item';
import Line from '../Line/Line';
import Path from '../Path/Path';
import Text from '../Text/Text';

const Quadrant = (props) => {
  const { colorScale } = useThemeContext();

  const dispatch = useDispatch();
  const activeQuadrant = useSelector(getActiveQuadrant);

  const { ref } = useScaleQuadrant(props);

  const ringWidth = props.width / 2;
  const radialAngle = ((2 * Math.PI) / 360) * props.angle;

  const handleOpacityChange = (opacity) => {
    const quadrants = document.querySelectorAll('.quadrant-wrapper');
    quadrants.forEach((quadrant) => {
      if (quadrant !== ref.current) {
        quadrant.style.opacity = opacity;
      }
    });
  };

  const onMouseOver = () => {
    if (activeQuadrant === null) {
      handleOpacityChange(0.5);
    }
  };

  const onMouseOut = () => {
    if (activeQuadrant === null) {
      handleOpacityChange(1);
    }
  };

  const onMouseClick = (e) => {
    const activeQuadrantIndex =
      activeQuadrant === null || activeQuadrant !== props.index ? props.index : null;
    const hasClass =
      e.target.classList.contains('number-of-users') || e.target.classList.value === '';
    if (hasClass) {
      return;
    }
    dispatch(setActiveQuadrantAction(activeQuadrantIndex));
  };

  const getDisplayStyle = (quadrantIndex) => {
    const display = activeQuadrant !== null && activeQuadrant !== quadrantIndex ? 'none' : 'block';
    return { display };
  };

  const radiuses = calculateRadiusDiminish(props.rings.length);

  return (
    <QuadrantWrapper
      className="quadrant-wrapper"
      transform={props.transform}
      id={props.name}
      data-testid={props.name}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onMouseClick}
      ref={(el) => (ref.current = el)}
      style={getDisplayStyle(props.index)}>
      <Line x2={ringWidth} y2={0} stroke={colorScale(props.index)} />
      {props.rings.map((ringValue, ringIndex) => {
        const ringsLength = props.rings.length;

        const leftMargin = 40 * (radiuses[ringIndex + 1] - radiuses[ringIndex]);

        return (
          <g key={`${props.index}-${ringIndex}`}>
            <Text name={ringValue} dx={leftMargin + radiuses[ringIndex] * ringWidth} />
            <Path
              quadIndex={props.index}
              ringIndex={ringIndex}
              ringWidth={ringWidth}
              ringsLength={ringsLength}
              quad_angle={radialAngle}
              outerRadius={radiuses[ringIndex + 1]}
              innerRadius={radiuses[ringIndex]}
            />
          </g>
        );
      })}
      {props.points.map((point) => {
        const radius = 800;
        const xOffset = point.x > 0 ? 0 : -20;
        const yOffset = point.y > 0 ? 0 : -20;
        const x = Math.min(Math.max(point.x + xOffset, -radius + xOffset), radius - xOffset);
        const y = Math.min(Math.max(point.y + yOffset, -radius + yOffset), radius - yOffset);

        return (
          <Item
            key={point.id}
            rotateDegrees={-props.rotateDegrees}
            numberOfUsers={point.numberOfUsers}
            isNew={point.isNew}
            name={point.name}
            quadrant={point.quadrant}
            size={point.size}
            fontSize={point.fontSize}
            x={x}
            y={y}
          />
        );
      })}
    </QuadrantWrapper>
  );
};

Quadrant.propTypes = {
  angle: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  points: PropTypes.array.isRequired,
  rings: PropTypes.array.isRequired,
  rotateDegrees: PropTypes.number.isRequired,
  transform: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
};

export default Quadrant;
