import React from 'react';

import GoBackButton from '../../components/common/GoBackButton/GoBackButton';

const EmptyPage = ({ isWidget }) => {
  return (
    <section className="section section-error">
      <h2 className="title">Oops! This radar is empty yet.</h2>
      {!isWidget && <GoBackButton />}
    </section>
  );
};

export default EmptyPage;
