import PropTypes from 'prop-types';
import React from 'react';

import TextWrapper from './Text.style';
import { useThemeContext } from '../../../hooks/useThemeContext';
// eslint-disable-next-line import/named
import { levelDescription } from '../Quadrant/technologyLevelDescription';
import { BootstrapTooltip } from '../Quadrant/ToolTip';

const Text = ({ dx, name }) => {
  const { fontSize, fontFamily } = useThemeContext();

  return (
    <BootstrapTooltip key={name} title={levelDescription[name]}>
      <TextWrapper className="quadrant" fontSize={fontSize} fontFamily={fontFamily} dx={dx}>
        {name}
      </TextWrapper>
    </BootstrapTooltip>
  );
};

Text.propTypes = {
  dx: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

export default Text;
