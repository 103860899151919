import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { technologyCategoriesButtons } from './buttonsConfig';
import { setActiveQuadrantAction } from '../../store/actions/quadrantActions';
import { getActiveQuadrant } from '../../store/selectors/quadrantSelectors';
import { BootstrapTooltip } from '../Radar/Quadrant/ToolTip';

const CategoryButtons = () => {
  const dispatch = useDispatch();
  const activeQuadrant = useSelector(getActiveQuadrant);

  const setOpacity = (quadrant, opacity) => (quadrant.style.opacity = opacity);

  const handleOpacityChange = (buttonId, opacity) => {
    const quadrants = document.querySelectorAll('.quadrant-wrapper');
    const button = technologyCategoriesButtons.find((button) => button.buttonId === buttonId);

    quadrants.forEach((quadrant) => {
      if (quadrant.id === button.category) {
        setOpacity(quadrant, 1);
      } else {
        setOpacity(quadrant, opacity);
      }
    });
  };

  const onMouseOver = (e) => {
    const buttonId = e.currentTarget.id;
    if (!buttonId) {
      return;
    }
    handleOpacityChange(buttonId, 0.5);
  };

  const onMouseOut = (e) => {
    const buttonId = e.currentTarget.id;
    if (!buttonId) {
      return;
    }
    handleOpacityChange(buttonId, 1);
  };

  const onMouseClick = (e) => {
    const buttonId = e.currentTarget.id;
    if (!buttonId) {
      return;
    }
    const button = technologyCategoriesButtons.find((button) => button.buttonId === buttonId);

    const newActiveQuadrant = activeQuadrant === button.quadrantIndex ? null : button.quadrantIndex;
    dispatch(setActiveQuadrantAction(newActiveQuadrant));
  };

  return (
    <div className="btn-container">
      {technologyCategoriesButtons.map(
        ({ quadrantIndex, color, buttonId, icon, category, tooltip }) => (
          <BootstrapTooltip key={category} title={tooltip}>
            <Button
              key={quadrantIndex}
              variant="contained"
              color={color}
              id={buttonId}
              startIcon={icon}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onClick={onMouseClick}
              className={`btn-category ${
                activeQuadrant === quadrantIndex
                  ? 'active'
                  : activeQuadrant !== null
                    ? 'inactive'
                    : ''
              }`}>
              {category}
            </Button>
          </BootstrapTooltip>
        )
      )}
    </div>
  );
};

export default CategoryButtons;
