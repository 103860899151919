import axios from 'axios';

import { getToken } from '../security/keycloak';

const sendAnalytic = (value) => {
  if (value && process.env.REACT_APP_WIDGET !== 'true') {
    axios.post(process.env.REACT_APP_API_URL + 'analytic/desiredTechnology/add', value, {
      headers: {
        'Content-Type': 'text/plain',
        Authorization: getToken()
      }
    });
  }
};

export default sendAnalytic;
