import { Code, Computer, Construction, Language } from '@mui/icons-material';

import { technologyCategory } from '../../models/technologyCategory';

export const technologyCategoriesButtons = [
  {
    buttonId: 'languages-frameworks-btn',
    category: technologyCategory.LANGUAGES_FRAMEWORKS,
    quadrantIndex: 3,
    color: 'primary',
    icon: <Code />,
    tooltip:
      'LANGUAGES is a fundamental building blocks that developers use to create software applications, ' +
      'and is a formal system for giving' +
      ' instructions to a computer, "FRAMEWORK" is a pre-built set of libraries'
  },
  {
    buttonId: 'techniques-btn',
    category: technologyCategory.TECHNIQUES,
    quadrantIndex: 2,
    color: 'secondary',
    icon: <Language />,
    tooltip:
      'Specific methods, practices, or procedures used to accomplish a particular task, solve ' +
      'a problem, or achieve a goal within the context of work'
  },
  {
    buttonId: 'platforms-btn',
    category: technologyCategory.PLATFORMS,
    quadrantIndex: 1,
    color: 'success',
    icon: <Computer />,
    tooltip:
      'Combination of hardware and software that serves as a foundation or infrastructure for running applications or services. ' +
      'Platforms are essential for the development, deployment, and operation of software, ' +
      'and they can vary significantly depending on the specific use case'
  },
  {
    buttonId: 'tools-btn',
    category: technologyCategory.TOOLS,
    quadrantIndex: 0,
    color: 'warning',
    icon: <Construction />,
    tooltip:
      'Software, hardware, or equipment used to perform specific tasks, solve problems, or ' +
      'support various functions to manage systems, develop software, monitor networks, troubleshoot issues, and more'
  }
];
