import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setAnalyticSelectedTechnologyAction,
  setHoveredTechnologyAction,
  setSelectedTechnologyAction
} from '../../../store/actions/quadrantActions';
import {
  getIsHoveredTechnology,
  getIsTechnologySelected
} from '../../../store/selectors/quadrantSelectors';
import TechnologyItemInfo from '../TechnologyItemInfo/TechnologyItemInfo';
import './technologyItem.scss';

const TechnologyItem = ({
  index,
  name,
  technologyDescription,
  contacts,
  tags,
  listContainerRef
}) => {
  const dispatch = useDispatch();
  const isTechnologyHovered = useSelector((state) => getIsHoveredTechnology(state, name));
  const isTechnologySelected = useSelector((state) => getIsTechnologySelected(state, name));

  const itemRef = useRef(null);

  const scrollContainerToSelectedItem = () => {
    const fixedOffset = 250;
    const selectedItem = itemRef.current;
    listContainerRef.current.scrollTop = selectedItem.offsetTop - fixedOffset;
  };

  useEffect(() => {
    if (isTechnologySelected) {
      scrollContainerToSelectedItem();
    }
  }, [isTechnologySelected]);

  useEffect(() => {
    return () => dispatch(setSelectedTechnologyAction(''));
  }, [name]);

  const onMouseOver = () => {
    dispatch(setHoveredTechnologyAction(name));
  };

  const onMouseOut = () => {
    dispatch(setHoveredTechnologyAction(''));
  };

  const onMouseClick = (e) => {
    if (!e.target.classList.contains('contact-name')) {
      if (isTechnologySelected) {
        dispatch(setSelectedTechnologyAction(''));
      } else {
        dispatch(setAnalyticSelectedTechnologyAction(name));
      }
    }
  };

  return (
    <div
      className="tech-item"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onMouseClick}
      ref={itemRef}>
      <p className={isTechnologyHovered || isTechnologySelected ? 'highlight' : ''}>
        {index}. {name}
      </p>
      {isTechnologySelected && (
        <TechnologyItemInfo
          technologyDescription={technologyDescription}
          contacts={contacts}
          tags={tags}
          name={name}
        />
      )}
    </div>
  );
};

TechnologyItem.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      projectName: PropTypes.string.isRequired,
      technologyRing: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  technologyDescription: PropTypes.string.isRequired,
  listContainerRef: PropTypes.object
};

export default TechnologyItem;
