import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Legend from './Legend/Legend';
import Quadrant from './Quadrant/Quadrant';
import { RadarContents } from './Radar.style';
import { ThemeContext } from '../../context/ThemeContext';
import calculateRadiusDiminish from '../../helpers/calculateRadiusDiminish';
import { useThemeContext } from '../../hooks/useThemeContext';

//when point coordinates are calculated randomly, sometimes point coordinates
//get so close that it would be hard to read the textual part. When such
//collisions occur, the position generator retries. This constant defines the
//number of trials where it has to stop.
const MAX_COLLISION_RETRY_COUNT = 1000;

//default radar width
const DEFAULT_WIDTH = 900;

//extend width to right so that overflow text would be visible
const RIGHT_EXTENSION = 1.1;

const MAX_POINTS_THRESHOLD = 35;
const DEFAULT_ITEM_SIZE = '20';
const REDUCED_ITEM_SIZE = '15';
const DEFAULT_ITEM_INDEX_FONT_SIZE = '15';
const REDUCED_ITEM_INDEX_FONT_SIZE = '8';

const Radar = ({ rings, quadrants, data, filteredRadar }) => {
  const radarData = filteredRadar?.data || data;

  const { fontSize, fontFamily, colorScale } = useThemeContext();

  //margin of radar
  const margin = 5;

  //some internally used constants
  const angle = 360 / quadrants.length;

  //collision detection constants
  const tolerance = 20;

  //given the ring and quadrant of a value,
  //calculates x and y coordinates
  const processRadarData = (quadrants, rings, data) => {
    //order by rings. this will result in better collision
    //detection performance since it is harder to relocate
    // the points in the core ring
    data.sort(function (a, b) {
      return rings.indexOf(a.ring) - rings.indexOf(b.ring);
    });

    let collisionCount = 0;

    // go through the data
    const results = [];

    for (const i in data) {
      const entry = data[i];

      let quadrant_delta = 0;

      // figure out which quadrant this is
      const angle = (2 * Math.PI) / quadrants.length;
      for (let j = 0, len = quadrants.length; j < len; j++) {
        if (quadrants[j] === entry.quadrant) {
          quadrant_delta = angle * j;
        }
      }
      const coordinates = getRandomCoordinates(
        rings,
        entry,
        angle,
        quadrant_delta,
        results,
        collisionCount
      );
      if (collisionCount < MAX_COLLISION_RETRY_COUNT) {
        collisionCount = coordinates.collisionCount;
      }

      const itemsCount = data.filter(
        (element) => element.quadrant === entry.quadrant && element.ring === entry.ring
      ).length;

      const size = itemsCount >= MAX_POINTS_THRESHOLD ? REDUCED_ITEM_SIZE : DEFAULT_ITEM_SIZE;
      const fontSize =
        entry.numberOfUsers !== null && entry.numberOfUsers.toString().length > 2
          ? REDUCED_ITEM_INDEX_FONT_SIZE
          : DEFAULT_ITEM_INDEX_FONT_SIZE;

      const technology = {
        id: entry.index,
        numberOfUsers: entry.numberOfUsers,
        name: entry.name,
        quadrant: entry.quadrant,
        ring: entry.ring,
        isNew: entry.isNew,
        x: coordinates.x,
        y: coordinates.y,
        size,
        fontSize
      };

      results.push(technology);
    }

    return results;
  };

  //used by processRadarData.
  //generates random coordinates within given range
  const getRandomCoordinates = (
    rings,
    entry,
    angle,
    quadrant_delta,
    results,
    collisionCount = 0
  ) => {
    const polarToCartesian = (r, t) => {
      const x = r * Math.cos(t);
      const y = r * Math.sin(t);
      return { x: x, y: y };
    };

    const getPositionByQuadrant = (radiusArray) => {
      const ringCount = rings.length;
      const margin = 0.2;
      const ringIndex = rings.indexOf(entry.ring);
      const posStart = radiusArray[ringIndex] + (1 / ringCount) * margin;
      const posLength =
        Math.random() *
        (radiusArray[ringIndex + 1] - radiusArray[ringIndex] - 2 * ((1 / ringCount) * margin));
      return posStart + posLength;
    };

    const hasCollision = (results, coordinates) => {
      if (collisionCount >= MAX_COLLISION_RETRY_COUNT) {
        return false;
      }

      for (const result of results) {
        const dx = result.x - coordinates.x;
        const dy = result.y - coordinates.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance <= tolerance) {
          return ++collisionCount < MAX_COLLISION_RETRY_COUNT;
        }
      }
      return false;
    };

    const radiusArray = calculateRadiusDiminish(rings.length);

    const randomPosition = getPositionByQuadrant(radiusArray);
    const positionAngle = Math.random();
    const ringWidth = DEFAULT_WIDTH / 2 - 10;

    //theta is the position in the quadrant
    const theta = positionAngle * angle + quadrant_delta;
    const radialDistance = randomPosition * ringWidth;

    const data = polarToCartesian(radialDistance, theta);

    //recalculate if there is a collision
    const collision = hasCollision(results, data);
    if (collision) {
      return getRandomCoordinates(rings, entry, angle, quadrant_delta, results, collisionCount);
    }

    //report number of collisions detected
    data.collisionCount = collisionCount;
    return data;
  };

  const points = useMemo(() => {
    return processRadarData(quadrants, rings, radarData);
  }, [JSON.stringify(quadrants), JSON.stringify(rings), JSON.stringify(radarData)]);

  return (
    <ThemeContext.Provider
      value={{
        fontSize,
        itemFontSize: fontSize,
        fontFamily: fontFamily,
        colorScale,
        quadrantsConfig: {}
      }}>
      <Legend />
      <RadarContents
        width={DEFAULT_WIDTH * RIGHT_EXTENSION}
        height={DEFAULT_WIDTH}
        style={{ margin: margin }}>
        <g transform={`translate(${DEFAULT_WIDTH / 2}, ${DEFAULT_WIDTH / 2})`}>
          {quadrants.map((value, index) => {
            //get points that belong to this quadrant
            const filteredPoints = points.filter((element) => element.quadrant === value);
            return (
              <g key={index}>
                <Quadrant
                  transform={`rotate(${
                    (360 / quadrants.length) * index
                  }) translate(${margin}, ${margin})`}
                  rotateDegrees={(360 / quadrants.length) * index}
                  width={DEFAULT_WIDTH - 2 * margin}
                  index={index}
                  rings={rings}
                  points={filteredPoints}
                  angle={angle}
                  name={value}
                />
              </g>
            );
          })}
        </g>
      </RadarContents>
    </ThemeContext.Provider>
  );
};

Radar.propTypes = {
  quadrants: PropTypes.array.isRequired,
  data: PropTypes.array,
  filteredRadar: PropTypes.object,
  rings: PropTypes.array
};

export default Radar;
