import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getActiveQuadrant } from '../../../store/selectors/quadrantSelectors';
import TechnologyItem from '../TechnologyItem/TechnologyItem';
import './ringSection.scss';

const RingSection = ({ currentRing, radarData, listContainerRef }) => {
  const activeQuadrant = useSelector(getActiveQuadrant);

  const technologies = useMemo(() => {
    return radarData.data.filter(
      ({ ring, quadrant }) =>
        ring === currentRing && quadrant === radarData.quadrants[activeQuadrant]
    );
  }, [activeQuadrant, currentRing, radarData]);

  const haveTechnologies = technologies.length > 0;

  return (
    <div>
      {haveTechnologies && <h3 className="ring-name">{currentRing}</h3>}
      <div className="tech-items">
        {technologies.map(({ index, name, technologyDescription, contacts, tags }) => (
          <TechnologyItem
            key={index}
            index={index}
            name={name}
            technologyDescription={technologyDescription}
            contacts={contacts}
            tags={tags}
            listContainerRef={listContainerRef}
          />
        ))}
      </div>
    </div>
  );
};

RingSection.propTypes = {
  currentRing: PropTypes.string.isRequired,
  radarData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        quadrant: PropTypes.string.isRequired,
        ring: PropTypes.string.isRequired
      })
    ).isRequired,
    quadrants: PropTypes.arrayOf(PropTypes.string.isRequired),
    rings: PropTypes.arrayOf(PropTypes.string.isRequired)
  }).isRequired,
  listContainerRef: PropTypes.object
};

export default RingSection;
