import PropTypes from 'prop-types';
import React from 'react';

const Line = (props) => {
  return <line x1="0" y1="0" x2={props.x2} y2={props.y2} stroke={props.stroke} role="line"></line>;
};

Line.propTypes = {
  stroke: PropTypes.string.isRequired,
  x2: PropTypes.number.isRequired,
  y2: PropTypes.number.isRequired
};

export default Line;
