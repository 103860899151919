import React from 'react';
import './SwitchButton.scss';

const SwitchButton = ({ activeToggle, setActiveToggle }) => {
  const handleClick = () => {
    setActiveToggle(activeToggle === 'and' ? 'or' : 'and');
  };

  return (
    <div className="switch-btn-container" onClick={handleClick}>
      <button className={`switch-btn ${activeToggle === 'and' ? 'active' : ''}`}>AND</button>
      <button className={`switch-btn ${activeToggle === 'or' ? 'active' : ''}`}>OR</button>
    </div>
  );
};

export default SwitchButton;
