import PropTypes from 'prop-types';
import React from 'react';

const Circle = ({ width, height, fill, numberOfUsers, fontSize }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 495.36 495.36"
    width={width}
    height={height}
    data-testid="circle">
    <path
      fill={fill}
      d="M247.68,0C110.89,0,0,110.89,0,247.68s110.89,247.68,247.68,247.68c136.693-0.235,247.445-110.988,247.68-247.68
		    C495.36,110.89,384.47,0,247.68,0z"
    />
    <text className="number-of-users" x="50%" y="50%" style={{ fontSize: `${fontSize}em` }}>
      {!numberOfUsers ? '' : numberOfUsers}
    </text>
  </svg>
);

Circle.propTypes = {
  fill: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string
};

export default Circle;
